import { keyframes } from '@emotion/react'
import * as CSS from 'csstype'
import { createStyles } from 'utils/css'
import theme from 'utils/theme'

import { IButtonProps, IButtonStyles } from './Button.types'

const fadeIn = keyframes`
  from {
    background: ${theme.colors.grey20};
    transform: scale(0.975);
  }
  to {
    transform: scale(1);
    background: ${theme.colors.grey25};
  }
`

export const setVariantStyles = (
  variant: IButtonProps['variant'],
  { colors, treatments }: typeof theme,
  brandColor: any
) => {
  const primaryColor = brandColor?.hex || colors.gradientPurple

  switch (variant) {
    case 'outline':
      return {
        backgroundColor: colors.light,
        color: colors.grey25,
        border: `1px solid ${colors.grey15}`,
        '&:hover': {
          backgroundColor: colors.grey5
        },
        '&:focus': {
          border: `1px solid ${colors.grey25}`
        },
        '&:disabled': {
          backgroundColor: colors.light,
          color: colors.grey25,
          ...treatments.disabled
        }
      }
    case 'text':
      return {
        backgroundColor: colors.transparent,
        padding: 0,
        fontWeight: 400,
        textAlign: 'left',
        ...treatments.links,
        '&:disabled': {
          backgroundColor: colors.transparent,
          color: 'inherit',
          ...treatments.disabled
        }
      }
    case 'primary':
    default:
      return {
        background: primaryColor,
        color: brandColor
          ? brandColor.isDark
            ? colors.light
            : colors.grey25
          : colors.light,
        '&:hover': {
          background: brandColor
            ? brandColor.hsl.darken(0.2).hex()
            : colors.primary
        }
      }
  }
}

export const root = ({
  brandColor,
  buttonSize,
  display,
  iconPlacement,
  loading,
  padding,
  styles,
  variant
}: IButtonStyles) =>
  createStyles(
    ({ buttons: { fonts, radii, sizes }, colors, spacing, treatments }) => {
      const defaultStyles = {
        ...treatments.button,
        ...treatments.focus,
        display,
        alignItems: 'center',
        padding: spacing(!padding ? sizes[buttonSize] : padding, 'rem', 1),
        fontSize: fonts[buttonSize],
        lineHeight: fonts[buttonSize],
        borderRadius: radii.xxl,
        width: display === 'block' && '100%',
        textDecoration: 'none !important',
        overflow: loading && 'hidden',
        '&:disabled': {
          background: colors.grey25,
          color: colors.grey5,
          ...treatments.disabled
        },
        ...setVariantStyles(variant, theme, brandColor),
        '& > div': {
          display: 'inline',
          margin: `0 ${spacing(0.5)}`
        },
        '& svg': {
          margin:
            iconPlacement === 'none'
              ? 0
              : iconPlacement === 'right'
              ? spacing([0, 0, 0, 0.5])
              : spacing([0, 0.5, 0, 0])
        },
        animation: loading && `${fadeIn} 1.5s ease-in-out infinite alternate`
      }

      return { ...defaultStyles, ...styles }
    }
  )

export const loader: CSS.Properties = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: '0 !important',
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'center',
  visibility: 'visible'
}

/**
 * used for side drawer CTA
 */
export const wideButtonStyles = {
  width: theme.size(23.5)
}

/**
 * used for ctas in default layout
 */
export const increasedSidePadding = {
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3)
}

export default root
